<template>
  <div>
    <Panel header="Reserva Cartão Detalhado" class="mt-3">
      <div class="formgrid grid">
        <div class="field col-6 md:col-6">
          <Button
            label="Voltar"
            icon="pi pi-chevron-left"
            class="mt-2 mr-2 p-button-sm p-button-secondary"
            @click="$router.back()" />
        </div>
      </div>
      <form>
        <div class="p-fluid formgrid grid">
          <div class="field col-4 md:col-4">
            <label for="tipo">Tipo</label>
            <InputText
              id="numeroOficio"
              v-model="historicoConsignacao.tipo"
              :disabled="true" />
          </div>
          <div class="field col-4 md:col-4">
            <label for="motivo">Motivo</label>
            <InputText
              id="motivo"
              v-model="historicoConsignacao.motivo"
              :disabled="true" />
          </div>
          <div class="field col-4 md:col-4">
            <label for="DataDocumento">Data do documento</label>
            <InputText
              id="DataDocumento"
              v-model="dataDocumento"
              :disabled="true" />
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="observacao">Observação</label>
            <InputText
              id="observacao"
              v-model="historicoConsignacao.observacao"
              :disabled="true" />
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="alteradoPor">Alterado Por</label>
            <InputText
              id="alteradoPor"
              v-model="historicoConsignacao.alteradoPor"
              :disabled="true" />
          </div>
          <div class="field col-6 md:col-6">
            <label for="alteradoEm">Alterado Em</label>
            <InputText id="alteradoEm" v-model="alteradoEm" :disabled="true" />
          </div>
        </div>
        <div v-if="isJudicial" class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="numeroOficio">Número do ofício</label>
            <InputText
              id="numeroOficio"
              v-model="historicoConsignacao.numeroOficio"
              :disabled="true" />
          </div>
          <div class="field col-6 md:col-6">
            <label for="numeroProcesso">Número do processo</label>
            <InputText
              id="numeroProcesso"
              v-model="historicoConsignacao.numeroProcesso"
              :disabled="true" />
          </div>

          <div class="field col-6 md:col-6">
            <label for="juizado">Juizado</label>
            <InputText
              id="juizado"
              v-model="historicoConsignacao.juizado"
              :disabled="true" />
          </div>

          <div class="field col-6 md:col-6">
            <label for="nomeJuiz">Nome do Juiz</label>
            <InputText
              id="nomeJuiz"
              v-model="historicoConsignacao.nomeJuiz"
              :disabled="true" />
          </div>
        </div>
      </form>
    </Panel>
  </div>
</template>

<script>
import moment from 'moment'
import HistoricoConsignacaoService from '@/service/HistoricoConsignacaoService.js'

export default {
  data() {
    return {
      tipo: null,
      motivo: '',
      dataDocumento: null,
      alteradoEm: null,
      dataAtual: null,
      situacao: null,
      isJudicial: false,
      idCartao: this.$route.params.idCartao,
      historicoConsignacao: null,
    }
  },

  created() {
    this.historicoConsignacaoService = new HistoricoConsignacaoService(
      this.$http,
    )
    this.historicoConsignacaoService
      .getHistoricoConsignacaoById(this.idCartao)
      .then((res) => {
        this.historicoConsignacao = res
        this.isJudicial = this.historicoConsignacao.motivo === 'JUDICIAL'
        this.dataDocumento = this.formatarData(
          this.historicoConsignacao.dataDocumento,
        )
        this.alteradoEm = this.formatarData(
          this.historicoConsignacao.auditMetadata.alteradoEm,
        )
      })
      .catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Ocorreu um erro. Tente novamente!',
          life: 10000,
        })
      })
  },

  methods: {
    formatarData(value) {
      if (!value) return ''
      return moment(value).format('DD/MM/YYYY')
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
